export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default class TaskService {
  entryPoint = "/core/task";

  constructor(client) {
    this.client = client;
  }

  async pullTask(
    taskId,
    followNext = false,
    MAXRETRIES = 60,
    INTERVAL = 1000,
    TIMEOUT = 10
  ) {
    for (let i = 0; i < MAXRETRIES; i++) {
      await sleep(INTERVAL);
      try {
        const { data } = await this.client.get(`${this.entryPoint}/${taskId}/`);
        const result = JSON.parse(data.result);
        // TODO - B.L - 25/02/2021 - Refacto task in backend so it always returns next_task as an Array
        if (followNext && result && result.next_task) {
          return this.pullTask(
            result.next_task,
            followNext,
            MAXRETRIES,
            INTERVAL,
            TIMEOUT
          );
        }
        if (followNext && result && result.next_tasks)
          return this.pullMultipleTask(
            data.next_tasks,
            followNext,
            MAXRETRIES,
            INTERVAL,
            TIMEOUT
          );
        return data;
      } catch (error) {
        if (!(error.response && error.response.status === 404)) {
          return { status: "FAILURE" };
        }
        // If we still receive a 404 after TIMEOUT seconds
        // which means celery task still not retrieved
        // ==> Stop pulling
        if (i > TIMEOUT) {
          return { status: "TIMEOUT" };
        }
      }
    }
  }

  async pullMultipleTask(
    taskIds,
    followNext = false,
    MAXRETRIES = 60,
    INTERVAL = 1000,
    TIMEOUT = 10
  ) {
    return Promise.all(
      taskIds.map((taskId) =>
        this.pullTask(taskId, followNext, MAXRETRIES, INTERVAL, TIMEOUT)
      )
    );
  }
}
