import RapsoError from "./RapsoError";

export default class AgencyService {
  entryPoint = "/agency";

  constructor(client) {
    this.client = client;
  }

  async getAgencies() {
    try {
      const { data } = await this.client.get(this.entryPoint);
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }
}
