import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { utils } from "@socotec.io/socio-vue-components";
import Plugin from "@/models/plugin";

const state = {};
const mutations = {};

const getters = {
  getPluginsList: () => {
    return Plugin.all();
  },
};

const actions = {
  async fetchPluginByUuid(_, userUuid) {
    const request = new socioGrpcClient.plugins.plugin.UserPluginListRequest();
    const metadata = {
      filters: {
        user_uuid: userUuid,
      },
    };
    try {
      const response =
        await socioGrpcClient.plugins.plugin.UserPluginControllerPromiseClient.list(
          request,
          utils.buildMetadata(metadata)
        );
      const { resultsList } = response.toObject();
      const { pluginsList = [] } = resultsList?.at(0) ?? {}
      Plugin.insert({ data: pluginsList });
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
