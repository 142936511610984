const ConnectorList = () =>
  import(
    /* webpackChunkName: "ConnectorList" */ "@/components/AppCenter/Sedi/ConnectorList"
  );
const WorkspaceList = () =>
  import(
    /* webpackChunkName: "ConnectorList" */ "@/components/AppCenter/Sedi/WorkspaceList"
  );

export default [
  {
    path: "",
    name: "sedi-connectors",
    component: ConnectorList,
  },
  {
    path: "workspaces",
    name: "sedi-workspaces",
    component: WorkspaceList,
  },
];
