import { socioGrpcClient } from "@/setup/socioGrpcClient";
import store from "@/store/index"

export default class ReportGeneratorService {

  async getReportsList(metadata) {
    const request = new socioGrpcClient.report_generator.reports.ReportListRequest();
    try {
      const response = await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        metadata
      );
      return response.toObject();
    } catch (error) {
      console.error(error);
      store.dispatch("notifications/showErrorNotification", "Error loading reports")
    }
  }

  // INFO - AM - 10/08/2022 - data is a js object corresponding to the ReportRefreshRequest (report_generator/report.proto) message
  async refresh(data, metadata, callback) {

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports.ReportRefreshRequest,
      data
    );
    try {
      const stream = await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.refresh(
        request,
        metadata
      );

      return new Promise((resolve, reject) => {

        stream.on("data", async response => {
          response = response.toObject();
          if(response.code === this.REPORT_MESSAGE_CODE.SUCCESS) {
            resolve(response)
          } else if (this.ERROR_REPORT_STATUS.includes(response.code)) {
            console.error(response);
            store.dispatch("notifications/showErrorNotification", `Error regenerating report ${data.uuid}`)
            reject(response)
          } else {
            callback(data.uuid, response)
          }
        })
      });
    } catch (error) {
      console.error(error);
      store.dispatch("notifications/showErrorNotification", "Error refresh reports")
    }
  }

  async destroy(reportGeneratorUuid, metadata) {

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports.ReportDestroyRequest,
      {
        uuid: reportGeneratorUuid
      }
    );
    try {
      const response = await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.destroy(
        request,
        metadata
      );
      return response.toObject()
    } catch(error) {
      console.error(error);
      store.dispatch("notifications/showErrorNotification", "Error deleting one report")
    }
  }

  SERVICE_ID_ENUM = {
    RAPSOTEC: "rapsotec",
    RAPSOSPS: "rapsosps"
  }


  REPORT_STATUS = {
    ERROR: "ERROR",
    GENERATING: "GENERATING",
    READY: "READY",
    SENT: "SENT",
    TEMPORARY: "TEMPORARY",
    NOT_VALIDATED: "NOT_VALIDATED"
  }

  REPORT_MESSAGE_CODE = {
    SUCCESS: "SUCCES",
    GENERATING: "GENERATING",
    UPLOAD_FAILED: "UPLOAD_FAILED",
    INVALID_REPORT: "INVALID_REPORT",
    INVALID_DATA: "INVALID_DATA",
    ERROR: "ERROR",
    NOT_FOUND: "NOT_FOUND",
  }

  ERROR_REPORT_STATUS = [this.REPORT_MESSAGE_CODE.ERROR, this.REPORT_MESSAGE_CODE.UPLOAD_FAILED, this.REPORT_MESSAGE_CODE.INVALID_REPORT, this.REPORT_MESSAGE_CODE.INVALID_DATA, this.REPORT_MESSAGE_CODE.NOT_FOUND]  
}