import RapsoError from "./RapsoError";

export default class CaseService {
  entryPoint = "/paginated-case/";

  constructor(client) {
    this.client = client;
  }

  async getCases(params) {
    try {
      const { data } = await this.client.get(this.entryPoint, { params });
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }
}
