export const verifyUserAccess = (store, groupsWithAccess) => {
  return async (to, from, next) => {
    if (store.getters["currentUser/isDev"]) {
      return next();
    }

    if (to.meta.onlyDev) {
      return next("/");
    }

    const authorizedGroups = to.meta.groupsWithAccess ? to.meta.groupsWithAccess : groupsWithAccess;

    if (
      authorizedGroups &&
      store.getters["currentUser/checkAppAccess"](authorizedGroups)
    ) {
      return next();
    }

    return next("/");
  };
};
