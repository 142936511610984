import Vue from "vue";

Vue.filter("lowercase", function (value) {
  if (value) {
    value = value.toString();
    return value.toLowerCase();
  }
});

Vue.filter("uppercase", function (value) {
  if (value) {
    value = value.toString();
    return value.toUpperCase();
  }
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
