
const Operations = () =>
  import(
    /* webpackChunkName: "Operations" */ "@/components/AppCenter/Rapsosps/Operations"
  );

const Dashboard = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/components/AppCenter/Rapsosps/Dashboard"
  );

export default [
  {
    path: "project-list",
    name: "rapsosps-list",
    component: Operations,
  },
  {
    path: "",
    name: "rapsosps-dashboard",
    component: Dashboard,
  }
];