const Atlas = () =>
  import(/* webpackChunkName: "Rapsotec" */ "@/components/AppCenter/Atlas");

const Rapsotec = () =>
  import(/* webpackChunkName: "Rapsotec" */ "@/components/AppCenter/Rapsotec");

const RapsotecSPS = () =>
  import(
    /* webpackChunkName: "RapsotecSPS" */ "@/components/AppCenter/RapostecSPS"
  );

const CarCheck = () =>
  import(/* webpackChunkName: "CarCheck" */ "@/components/AppCenter/CarCheck");

const Sedi = () =>
  import(/* webpackChunkName: "CarCheck" */ "@/components/AppCenter/Sedi");

import store from "@/store";
import { SERVICES } from "@/utils/services";
import atlas from "./appChildrens/atlas";
import rapsotec from "./appChildrens/rapsotec";
import rapsosps from "./appChildrens/rapsotec-sps";
import sedi from "./appChildrens/sedi";
import { verifyUserAccess } from "./userAccessMiddleware";

export default [
  {
    path: "atlas",
    component: Atlas,
    children: atlas,
    beforeEnter: verifyUserAccess(store, SERVICES["Atlas"].groupsWithAccess),
  },
  {
    path: "rapsotec",
    component: Rapsotec,
    children: rapsotec,
    beforeEnter: verifyUserAccess(store, SERVICES["Rapsotec"].groupsWithAccess),
  },
  {
    path: "rapsotec-sps",
    name: "rapsotec-sps",
    children: rapsosps,
    component: RapsotecSPS,
    beforeEnter: verifyUserAccess(store, SERVICES["RapsoSPS"].groupsWithAccess),
  },
  {
    path: "car-check",
    name: "car-check",
    component: CarCheck,
    beforeEnter: verifyUserAccess(store, SERVICES["CarCheck"].groupsWithAccess),
  },
  {
    path: "sedi",
    name: "sedi",
    component: Sedi,
    children: sedi,
    beforeEnter: verifyUserAccess(store, SERVICES["Rapsotec"].groupsWithAccess),
  },
];
