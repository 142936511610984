const Dashboard = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/components/AppCenter/Rapsotec/Dashboard"
  );

// const Historic = () =>
//   import(/* webpackChunkName: "Historic" */ "@/components/AppCenter/Rapsotec/Historic");

const Canevas = () =>
  import(
    /* webpackChunkName: "Canevas" */ "@/components/AppCenter/Rapsotec/Canevas"
  );

const Reports = () =>
  import(
    /* webpackChunkName: "Reports" */ "@/components/AppCenter/Rapsotec/Reports"
  );

export default [
  {
    path: "",
    name: "rapso-dashboard",
    component: Dashboard,
  },
  //  Hidden until we have the page
  // {
  //   path: "historic",
  //   name: "historic",
  //   component: Historic,
  // },
  {
    path: "projects-list",
    name: "projects-list",
    meta: {
      onlyDev: true,
    },
    component: Canevas,
  },
  {
    path: "reports",
    name: "reports",
    meta: {
      onlyDev: true,
    },
    component: Reports,
  },
];
