import RapsoError from "./RapsoError";

export default class DataImportService {
  entryPoint = "/dataimport/";

  constructor(client) {
    this.client = client;
  }

  async fetchProjectLogs(params) {
    try {
      // INFO - B.L - 22/02/2021
      // The endpoint import-logs contains the logs for backend models
      // We filter using the get paramater "type" to fetch only the logs concerning "projects"
      params.type = "project";
      const response = await this.client.get(`${this.entryPoint}import-logs/`, {
        params,
      });
      return response.data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }
}
