export default class RapsoError {
  constructor(data, code, message) {
    if (code !== undefined) {
      this.code = code;
    }
    if (message !== undefined) {
      this.message = message;
    }
    this.data = data;
    if (!code && !message) {
      this.generateAutomaticErrorCode(data);
    }
  }
  generateAutomaticErrorCode(err_data) {
    this.code = "UNKNOWN_ERROR";
    this.message = "An unknown error occured";
    console.log(err_data.toString())
    // Track unicity constraint error
    if (err_data.code && err_data.code === "ALREADY_EXISTS") {
      this.code = "VALIDATION_ERROR";
      this.message = "Unicity constraint error";
      return;
    }
    if (err_data && err_data.toString() === "Error: Request failed with status code 404") {
      this.code = "NOT_FOUND";
      this.message = "not found";
      return;
    }
    if (err_data.detail) {
      this.code = err_data.detail;
      return;
    }
    // Automatically get from axios response
    if (!err_data.response) {
      return;
    }
    if (err_data.response.data.code) {
      this.code = err_data.response.data.code;
    }
    if (err_data.message) {
      this.message = err_data.response.data.message;
    }
  }
}
