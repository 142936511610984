import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";

// import VuexORMAxios from "@vuex-orm/plugin-axios"
import {
  Project,
  User,
  AgencyGroup,
  Agency,
  Report,
  Case,
  Client,
  Notification,
} from "@socotec.io/socio-vue-components";

import Plugin from "@/models/plugin";

import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";
import { rapsotecClient } from "@/setup/axios";
import { notifications } from "@socotec.io/socio-vue-components";
import currentUser from "./modules/currentUser";
import pluginsModule from "./modules/plugins";
import setAccessToken from "@/setup/setAccessToken";

import { initSocioAppCenterAuth } from "@/setup/socioGrpcClient";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(Plugin);
database.register(Notification);

database.register(AgencyGroup);
database.register(Agency);
database.register(Project);
database.register(User);
database.register(Report);
database.register(Case);
database.register(Client);

const store = new Vuex.Store({
  state: {
    access_token: "",
  },
  mutations: {
    setAccessToken(state, token) {
      state.access_token = token;
    },
  },
  actions: {},
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          setAccessToken(user.access_token);
          rapsotecClient.refreshToken(user.access_token);
          initSocioAppCenterAuth(user.access_token);
        },
      }
    ),
    notifications,
    currentUser,

    plugins: pluginsModule,
  },
  plugins: [VuexORM.install(database)],
});

export default store;
