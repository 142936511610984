const AtlasUpdate = () =>
  import(
    /* webpackChunkName: "AtlasUpdate" */ "@/components/AppCenter/Atlas/AtlasUpdate"
  );

export default [
  {
    path: "atlas-logs",
    name: "atlas-logs",
    meta: {
      onlyDev: true,
    },
    component: AtlasUpdate,
  },
];
