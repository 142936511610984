import { Model } from "@vuex-orm/core";

export default class Plugin extends Model {
  static entity = "plugins";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      pluginHostname: this.string(),
      pluginName: this.string(),
      pluginUrl: this.string(),
    };
  }
}
