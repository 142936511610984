import Vue from "vue";
import VueI18n from "vue-i18n";
import { utils } from "@socotec.io/socio-vue-components";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
    dashboard: "DashBoard",
    connectingAgency: "Connecting agency",
    myMap: "MyMap",
    searchInMap: "Search in MyMap",
    myBoard: "My Board",
    sitemapComp: "Site Map",
    notifications: "Notifications",
    powerBI: "Power BI",
    applications: "Applications",
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
    dashboard: "Tableau de bord",
    connectingAgency: "Agence de rattachement",
    myMap: "MyMap",
    searchInMap: "Rechercher dans MyMap",
    myBoard: "Tableau de bord",
    sitemapComp: "Carte des chantiers",
    notifications: "Notifications",
    powerBI: "Power BI",
    applications: "Applications",
  },
};

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  silentFallbackWarn: true,
});
