import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import vuetify from "@/setup/vuetify";
import { i18n } from "@/setup/i18n";
import "@/utils/filters";

import SocioVueComponents from "@socotec.io/socio-vue-components";

Vue.use(SocioVueComponents);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
