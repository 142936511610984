import Vue from "vue";
import VueRouter from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store";
import appChildrens from "./childrens/appChildrens";
// import sitemapChildren from "./childrens/site-map";
import getCurrentUser from "@/router/middleware/getCurrentUser";

const AppCenter = () =>
  import(/* webpackChunkName: "AppCenter" */ "@/components/AppCenter");

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");

// const SiteMap = () =>
//   import(/* webpackChunkName: "AppCenter" */ "@/components/SiteMap");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppCenter,
    children: appChildrens,
    beforeEnter: getCurrentUser(store)
  },
  // {
  //   path: "/sitemap",
  //   component: SiteMap,
  //   children: sitemapChildren,
  //   beforeEnter: getCurrentUser(store),
  // },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
