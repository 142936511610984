import { utils } from "@socotec.io/socio-vue-components";

const groups = utils.groups;

export const SERVICES = {
  Atlas: {
    name: "atlas-logs",
    title: "Atlas",
    initial: "AT",
    color: "grey",
    groupsWithAccess: [groups.APP_GROUPS.RAPSOTEC, groups.CI_FRANCE.CT],
  },

  Rapsotec: {
    name: "rapso-dashboard",
    title: "Rapsotec",
    initial: "CT",
    color: "light-blue",
    groupsWithAccess: [groups.APP_GROUPS.RAPSOTEC, groups.CI_FRANCE.CT],
  },

  RapsoSPS: {
    name: "rapsotec-sps",
    title: "Rapso SPS",
    initial: "SPS",
    color: "light-green",
    groupsWithAccess: [groups.APP_GROUPS.RAPSO_SPS, groups.CI_FRANCE.SPS],
  },

  CarCheck: {
    name: "car-check",
    title: "Car Check",
    initial: "CC",
    color: "pink lighten-3",
    groupsWithAccess: [groups.APP_GROUPS.CARCHECK],
    display: true,
  },
  Sedi: {
    name: "sedi",
    title: "Sedi",
    initial: "Se",
    color: "orange lighten-2",
    groupsWithAccess: [groups.APP_GROUPS.RAPSO_SPS, groups.CI_FRANCE.SPS],
    display: true,
  },
};
