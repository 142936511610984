import RapsoError from "./RapsoError";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
export default class ProjectService {
  entryPoint = "/project/";

  constructor(client) {
    this.client = client;
  }

  async getRapsotecProjects(params) {
    try {
      const queryString = new URLSearchParams(params).toString();
      const { data } = await this.client.get(`/project/?${queryString}`);
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async getRapsotecProject(projectId) {
    try {
      const { data } = await this.client.get(`/project/${projectId}/`);
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async createProjectFromCase(body) {
    try {
      const { data } = await this.client.post(
        "/project/create_project_from_case/",
        body
      );
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async batchUpdatePilot(pilotArray) {
    try {
      await this.client.post(
        "/project/batch_update_project_managers/",
        pilotArray
      );
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async createDMSStructuration(projectIds) {
    try {
      const response = await this.client.post(
        "/project/create_dms_structuration/",
        { project_ids: projectIds }
      );
      return response.data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async fixWebmails(projectIds) {
    try {
      const request = new socioGrpcClient.rapsotec_back.section.ProjectFixWebmailsRequest()
      request.setProjectIdsList(projectIds)
      const response = await socioGrpcClient.rapsotec_back.section.ProjectControllerPromiseClient.fixWebmails(request, {})
      return response.toObject()
    } catch (error) {
      console.log(error)
      throw new RapsoError(error);
    }
  }
}
