import axios from "axios";
import AgencyService from "./api-rapsotec/AgencyService";
import CaseService from "./api-rapsotec/CaseService";
import DataImportService from "./api-rapsotec/DataImportService";
import ProjectService from "./api-rapsotec/ProjectService";
import StatsService from "./api-rapsotec/StatsService";
import TaskService from "./api-rapsotec/TaskService";
import UserService from "./api-rapsotec/UserService";
import ReportGeneratorService from "./api/ReportGeneratorService";

export default class RapsotecClient {
  constructor(baseURL) {
    this.client = this.getClient(baseURL);
    // RAPSOTEC OLD REST API
    this.agencyService = new AgencyService(this.client);
    this.projectService = new ProjectService(this.client);
    this.caseService = new CaseService(this.client);
    this.userService = new UserService(this.client);
    this.dataImportService = new DataImportService(this.client);
    this.statsService = new StatsService(this.client);
    this.taskService = new TaskService(this.client);

    // COMON GRPC API
    this.reportGeneratorService = new ReportGeneratorService();
  }

  getClient(baseURL) {
    let client = axios.create({
      baseURL: baseURL,
      headers: {},
    });
    return client;
  }

  refreshToken(accessToken) {
    this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
}
