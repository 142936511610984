import { utils } from "@socotec.io/socio-vue-components";

// INFO - MS - 15/06/2021 - [GRPC] Transform object keys in camelCase due to socio-vue-component adaptation to grpc response
export const snakeToCamel = (data) => {
  if (!data) {
    return;
  }
  Object.keys(data).forEach((key) => {
    if (key.includes("_")) {
      let newKey = key.replace(/([_][a-z])/g, (match) =>
        match.toUpperCase().replace("_", "")
      );
      Object.assign(data, { [newKey]: data[key] });
      delete data[key];
    }
  });
  return data;
};

export const renameKeys = (keysMap, obj) => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );
};
export const getColorForUser = (currentUser) => {
  const defaultColor = { main: "grey", background: "#e6eef5" };
  const groups = utils.groups;
  const groupsCT = [groups.APP_GROUPS.RAPSOTEC, groups.CI_FRANCE.CT];
  const groupsSPS = [
    groups.APP_GROUPS.RAPSO_SPS,
    "RAPSOSPS",
    groups.CI_FRANCE.SPS,
  ];

  if (currentUser.isInGroups(groupsCT))
    return { main: "#0082de", background: "#e6eef5" };
  if (currentUser.isInGroups(groupsSPS))
    return { main: "#00833F", background: "#EDF9F2" };
  return defaultColor;
};
