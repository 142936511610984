import RapsoError from "./RapsoError";
import dayjs from "dayjs";

export default class UserService {
  entryPoint = "/user-profiles/";
  dateFormat = "DD/MM/YYYY";

  constructor(client) {
    this.client = client;
  }

  async getUsers(params) {
    try {
      const { data } = await this.client.get(this.entryPoint, { params });
      return data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }

  async fetchInactiveUsers(
    queryParams = { role: "PROJECT_MANAGER", full_serializer: true }
  ) {
    try {
      if (!queryParams.last_login) {
        queryParams.last_login = dayjs()
          .subtract(1, "months")
          .format(this.dateFormat);
      }
      const params = new URLSearchParams(queryParams).toString();
      const response = await this.client.get(`${this.entryPoint}?${params}`);
      return response.data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }
}
