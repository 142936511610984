import RapsoError from "./RapsoError";

export default class StatsService {
  entryPoint = "/stats/";

  constructor(client) {
    this.client = client;
  }

  async fetchStatsSupport() {
    try {
      const response = await this.client.get(`${this.entryPoint}support/`);
      return response.data;
    } catch (error) {
      throw new RapsoError(error);
    }
  }
}
