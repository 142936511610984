import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import { i18n } from "@/setup/i18n";
import Vuetify, {
  VNavigationDrawer,
  VListItemAvatar,
  VList,
  VListItemContent,
  VImg,
  VListItemTitle,
  VListItemAction,
  VListItemGroup,
  VListItem,
  VListItemIcon,
  VSwitch,
  VApp,
  VBtn,
  VMain,
  VContainer,
  VRow,
  VCol,
  VSpacer,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VDivider,
  VTextField,
  VSelect,
  VExpandTransition,
  VDataTable,
  VIcon,
  VCheckbox,
  VTooltip,
  VAvatar,
  VAutocomplete,
  VChip,
  VSnackbar,
  VMenu,
  VDatePicker,
  VDialog,
  VChipGroup,
  VLayout,
  VProgressCircular,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VNavigationDrawer,
    VListItemAvatar,
    VList,
    VListItemContent,
    VImg,
    VListItemTitle,
    VListItemAction,
    VListItemGroup,
    VListItem,
    VListItemIcon,
    VSwitch,
    VApp,
    VBtn,
    VMain,
    VContainer,
    VRow,
    VCol,
    VSpacer,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VDivider,
    VTextField,
    VSelect,
    VExpandTransition,
    VDataTable,
    VIcon,
    VCheckbox,
    VTooltip,
    VAvatar,
    VAutocomplete,
    VChip,
    VSnackbar,
    VMenu,
    VDatePicker,
    VDialog,
    VChipGroup,
    VLayout,
    VProgressCircular,
  },
  iconfont: "md",
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00669e",
        secondary: "#fcb902",
        third: "#00ace8",
        black: "#333333",
        fifth: "#FFFFFF",
      },
      dark: {
        primary: "#00669e",
        secondary: "#fcb902",
        third: "#00ace8",
        black: "#333333",
        fifth: "#FFFFFF",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
